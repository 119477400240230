import axiosMiddleware from "./axiosMiddleware";
// --------------------------------------------------------------------------------
export const addBankCardAPI = ({ iban, currencyId }) => {
  let postData = {
    iban: "IR" + iban,
    currency_id: currencyId,
  };
  return axiosMiddleware({
    method: "post",
    url: "bank-account/create",
    data: postData,
  });
};
export const getBankCardsAPI = () => {
  return axiosMiddleware({
    method: "post",
    url: "bank-account/all",
  });
};
export const getPlansHistoryAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "plan/list",
  });
};
export const changeProfilePictureAPI = (formData) => {
  let postData = formData;
  return axiosMiddleware({
    method: "post",
    url: "user/profile",
    data: postData,
  });
};
export const createUserCompanyAPI = (formData) => {
  let postData = formData;
  return axiosMiddleware({
    method: "post",
    url: "user/company",
    data: postData,
  });
};
export const sendEmailTokenAPI = ({ email }) => {
  let postData = {
    email,
  };
  return axiosMiddleware({
    method: "post",
    url: "user/email-token",
    data: postData,
  });
};
export const verifyEmailTokenAPI = ({ token }) => {
  let postData = {
    token,
  };
  return axiosMiddleware({
    method: "post",
    url: "user/email-verify",
    data: postData,
  });
};
export const getUserCompanyAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "user/company/get",
  });
};
export const upgradePlanAPI = ({ planId, duration }) => {
  let postData = {
    plan_id: planId,
    duration,
  };
  return axiosMiddleware({
    method: "post",
    url: "plan/upgrade",
    data: postData,
  });
};
export const sendUserEmailAPI = ({ email }) => {
  let postData = {
    email,
  };
  return axiosMiddleware({
    method: "post",
    url: "user-email-send",
    data: postData,
  });
};
export const verifyUserEmailAPI = ({ email, token }) => {
  let postData = {
    email,
    token,
  };
  return axiosMiddleware({
    method: "post",
    url: "user-email-verify",
    data: postData,
  });
};
export const createUserPasswordAPI = ({ password, confirmPassword, email }) => {
  let postData = {
    password,
    confirmPassword,
    email,
  };
  return axiosMiddleware({
    method: "post",
    url: "user-create-password",
    data: postData,
  });
};
export const editUserAddressAPI = ({ state, city, postalCode, address }) => {
  let postData = {
    state,
    city,
    postal_code: postalCode,
    address,
  };
  return axiosMiddleware({
    method: "post",
    url: "user-edit-address",
    data: postData,
  });
};
export const getAcquaintancesListAPI = () => {
  return axiosMiddleware({
    method: "get",
    url: "acquaintances/list",
  });
};
export const createAcquaintanceAPI = ({ id }) => {
  let postData = {
    acquaintance_id: id,
  };
  return axiosMiddleware({
    method: "post",
    url: "acquaintance/create",
    data: postData,
  });
};
